@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-track-flex > .slick-list > .slick-track {
    @apply !w-full !flex !justify-between;
}
  
.category-navigation-strip > .slick-list > .slick-track {
  @apply !flex !justify-between;
}

.home-collection-slider > .slick-list {
  overflow: initial !important;
  z-index: 2;
}

.home-collection-slider > .slick-list > .slick-track > [aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear 0.2s, opacity 0.2s linear 0.2s;
}

.product-details-drawer-slider > .slick-list {
  overflow: initial !important;
}
