.dropzone {
  position: relative;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background: #ffffff;
  color: #bdbdbd;
  outline: none;
  transition: border-style .24s ease-in-out, border-color .24s ease-in-out, background .24s ease-in-out;
  width: 600px;
  height: 400px;
  cursor: pointer;
}

.dropzone.dragging {
  background: #fafafa;
  border-style: solid;
  border-color: black;
}