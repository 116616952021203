@tailwind base;
@tailwind components;
@tailwind utilities;

.react-dropdown-select-dropdown {
  @apply !w-auto !max-h-screen;
}

.rdrStartEdge, .rdrEndEdge {
  @apply !bg-theme_1_black;
}

.rdrMonthAndYearWrapper {
  @apply !pt-0;
}

.rdrMonthName {
  @apply !text-center !text-theme_1_grey1;
}

.rdrWeekDay {
  @apply !text-[10px] !text-theme_1_grey2 !font-semibold;
}

.rdrMonthAndYearPickers, .rdrInputRanges {
  @apply !hidden;
}

.rdrMonths.rdrMonthsHorizontal {
  @apply !mt-[-50px];
}

.rdrNextPrevButton {
  @apply !z-[1];
}

.rdrStaticRanges {
  @apply !flex-row !gap-[10px];
}

.rdrDefinedRangesWrapper, .rdrStaticRange {
  @apply !border-none !w-fit;
}

.rdrStaticRangeLabel {
  @apply !px-[10px];
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  @apply !bg-transparent;
}

.rdrDayToday .rdrDayNumber span:after {
  @apply !bg-current;
}

.rdrDay {
  @apply !text-theme_1_grey1;
}

.rdrInRange {
  @apply !rounded-none;
}

.rdrDayHovered .rdrDayNumber {
  @apply !font-semibold;
}

.rdrStartEdge,
.rdrDayStartPreview,
.rdrDayStartOfWeek > .rdrDayInPreview,
.rdrDayStartOfMonth > .rdrDayInPreview,
.rdrDayStartOfWeek > .rdrInRange,
.rdrDayStartOfMonth > .rdrInRange,
.rdrDayStartOfWeek > .rdrEndEdge,
.rdrDayStartOfMonth > .rdrEndEdge,
.rdrDayStartOfWeek > .rdrDayEndPreview,
.rdrDayStartOfMonth > .rdrDayEndPreview {
  @apply !rounded-l;
}

.rdrEndEdge,
.rdrDayEndPreview,
.rdrDayEndOfWeek > .rdrDayInPreview,
.rdrDayEndOfMonth > .rdrDayInPreview,
.rdrDayEndOfWeek > .rdrInRange,
.rdrDayEndOfMonth > .rdrInRange,
.rdrDayEndOfWeek > .rdrStartEdge,
.rdrDayEndOfMonth > .rdrStartEdge,
.rdrDayEndOfWeek > .rdrDayStartPreview,
.rdrDayEndOfMonth > .rdrDayStartPreview {
  @apply !rounded-r;
}
